<template>
  <div class="modal-overlay" @click.self="$emit('close')">
    <div class="modal">
      <slot name="modal-header"></slot>
      <button class="close-btn" @click="$emit('close')">
        <img src="@/assets/images/consulting/ic_close.svg" alt="닫기" />
      </button>
      <slot name="modal-contents"></slot>
    </div>
  </div>
</template>

<script>
  export default {
    name: "Modal"
  };
</script>

<style scoped>
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000;
  }

  .modal {
    position: relative;
    padding: 20px;
    border-radius: 8px;
    width: 100vw;
    height: 100dvh;
    display: flex;
    flex-direction: column;
    background: white;
    box-sizing: border-box;
  }

  .close-btn {
    position: absolute;
    right: 20px;
    top: 20px;
    width: 20px;
    height: 20px;
    background: none;
    border: none;
    font-size: 0;
    cursor: pointer;
    z-index: 1;
    padding: 0;
  }
  .modal-title {
    color: #000;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: -0.8px;
    padding: 0 0 20px;
  }
  .modal-contents {
    overflow-y: auto;
    height: calc(100dvh - 80px);
    overscroll-behavior: none;
  }
</style>
